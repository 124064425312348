<template>
  <div class="container">
    <div class="education-courses-list mt-4">
      <h4 style="text-align: center">Список курсов</h4>


      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <a href='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
client_id=d9d75a47-0f8c-4b50-80b9-3145ac446d92
&response_type=code
&redirect_uri=https://front.uib.kz&scope=https://graph.microsoft.com/Calendars.ReadWrite'
             class="btn btn-pill text-white btn-block btn-primary" @click="getCode()">
            Авторизация
          </a>
        </div>

      </div>


      <DataTable :value="courses" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="course_name" header="Название курса"></Column>
        <Column field="educationCurriculum.spAcademicYear.name" header="Учебный год"></Column>
        <Column field="educationCurriculum.study_course" header="Курс"></Column>
        <Column field="formationEducationProgram.semester" header="Семестр"></Column>
        <Column header="Преподаватель">
          <template #body="{data}">

            {{data.lecturePpsData.ppsData.lastname}} {{data.lecturePpsData.ppsData.firstname}}

          </template>
        </Column>
        <Column header="Действие">
          <template #body="{data}">
            <div v-if="data.lecture_microsoft_teams_url">
              Собрание уже создано
            </div>
            <div v-else>
              <Button icon="pi pi-link" label="Создать собрание Teams" @click="openCreateTeamsEventDialog(data.mdl_course_id)"/>
            </div>


          </template>
        </Column>




      </DataTable>

<!--      <ConfirmDialog group="createTeamsEvent"></ConfirmDialog>-->

      <Dialog v-model:visible="createTeamsEventDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Создать событие</h5>
        </template>

        <div class="col-md-12 mt-4">


          <div class="row mt-4">
            <label class="col-md-3 col-form-label">День недели</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeTeamsEventData('day', $event)">
                <option
                    v-for="(item, index) in [{day: 0, name: 'Выберите день недели'}, ...weeksDays]"
                    :value="item.day"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Начало</label>
            <div class="col-md-9">
              <input id="start_time" type="time" class="form-control"
                     placeholder="Начало"
                     @change="changeTeamsEventData('start_time', $event)">
            </div>
          </div>
          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Конец</label>
            <div class="col-md-9">
              <input id="end_time" type="time" class="form-control"
                     placeholder="Конец"
                     @change="changeTeamsEventData('end_time', $event)">
            </div>
          </div>



        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeCreateTeamsEventDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="postTeamsEvent"/>
        </template>
      </Dialog>




<!--      {{newTeamsEventInfo}}-->

    </div>
  </div>


</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import httpClient from "../../services/http.service";
import {getCookie, setCookie} from "@/utils/helpers/cookies.helpers";
export default {
  name: "EducationCoursesList",


  data() {
    return {
      urlDisciplineId: +this.$route.query.discipline_id || 0,
      urlSemester: +this.$route.query.semester || 0,
      urlCode: +this.$route.query.code || 0,
      courses: [],
      createTeamsEventDisplay: false,
      mdlCourseId: null,
      newTeamsEventInfo: {},
      weeksDays:[
        {
          day: '1',
          name: 'Понедельник'
        },
        {
          day: '2',
          name: 'Вторник'
        },
        {
          day: '3',
          name: 'Среда'
        },
        {
          day: '4',
          name: 'Четверг'
        },
        {
          day: '5',
          name: 'Пятница'
        }
        ,
        {
          day: '6',
          name: 'Суббота'
        }
      ]
    }
  },
  computed: {

  },

  methods: {
    getCode(){
      setCookie('URL_QUERY_REDIRECT_MS_GRAPH', JSON.stringify({discipline_id:  this.urlDisciplineId, semester: this.urlSemester}));
    },
    getToken(){
      this.GET_TOKEN()

    },
    openCreateTeamsEventDialog(mdl_course_id) {
      //this.mdlCourseId = mdl_course_id
      this.newTeamsEventInfo['mdl_course_id'] = mdl_course_id
      console.log()
      this.createTeamsEventDisplay = true
    },
    closeCreateTeamsEventDialog() {
      this.createTeamsEventDisplay = false
    },
    async postTeamsEvent() {
      this.createTeamsEventDisplay = false
      await this.POST_TEAMS_EVENT(this.mdlCourseId)
      await this.GET_COURSES_BY_DISCIPLINE_ID_AND_SEMESTER()

    },
    changeTeamsEventData(property, e, val = 'value') {
      const value = e.target[val]
      this.newTeamsEventInfo[property] = value
      console.log(this.newTeamsEventInfo, 'newTeamsEventInfo')
    },
    // openCreateTeamsEventDialog(mdl_course_id) {
    //   console.log(mdl_course_id, 'mdl_course_id')
    //   //this.deleteRatingId = id
    //   //let courseName = this.attestationInfos.find(i => i.rating_id == this.deleteRatingId)?.course_name
    //   this.$confirm.require({
    //     group: 'createTeamsEvent',
    //     message: `Вы точно хотите создать событие`,
    //     header: 'Создание события',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: async () => {
    //       await this.POST_TEAMS_EVENT(mdl_course_id)
    //
    //     }
    //   })
    // },

    async GET_TOKEN(code) {
      try {
        let url = 'microsoft-graph-connector/microsoft-connector/get-token' + '?access-token=' + getCookie('ACCESS_TOKEN')
            + '&code=' + code

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {

          this.$message({text: 'Успешно авторизовались'})

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_COURSES_BY_DISCIPLINE_ID_AND_SEMESTER() {
      try {
        let url = 'education_program/education-courses/get-courses-by-discipline-id-and-semester' + '?access-token=' + getCookie('ACCESS_TOKEN')
        + '&discipline_id=' + this.urlDisciplineId + '&semester='+ this.urlSemester

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {

          this.courses = data.success
          console.log(this.courses, 'courses')

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async POST_TEAMS_EVENT(mdl_course_id) {
      try {
        let url = 'microsoft-graph-connector/microsoft-connector/create-meeting' + '?access-token=' + getCookie('ACCESS_TOKEN')

        //this.newTeamsEventInfo['mdl_course_id'] = mdl_course_id
        const {
          status,
          data
        } = await httpClient.post(url, this.newTeamsEventInfo);
        if (status === 200) {
          console.log(data)
        }
      } catch (e) {
        console.log(e.response);
        this.$error({text: 'Произошла ошибка ' + JSON.stringify(e.response.data.errors)})
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

  },







  async mounted() {
    if(this.$route.query.code) {
      await this.GET_TOKEN(this.$route.query.code)
    }
    await this.GET_COURSES_BY_DISCIPLINE_ID_AND_SEMESTER()
    //await this.GET_TOKEN(this.urlCode)
  }
}
</script>

<style scoped>


</style>